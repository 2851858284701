@import "~@/styles/constants.scss";

.card {
    box-shadow: none;
    border: 1px solid $mitreSilver;
}
.content {
    p:not(:last-child) {
        margin-bottom: 2px;
    }

    p.title {
        margin-bottom: .8rem;
    }
}
// toggle hidden tool area
ul {
    padding-left: 0;
}
ul:last-of-type {
    margin-bottom: 0;
}
li.toolCategory {
    border-bottom: solid 1px $mitreSilver;
}
li.toolCategory:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
}
.toolsList {
    list-style-type: none;
    margin-right: 0.5rem;
}
.level:not(:last-child) {
    margin-bottom: 0;
}
.level-left {
    flex-shrink: 1;     // allow long text to wrap
}
.toolIcon {
    width: 1.3em;
    height: auto;
    margin-right: .5em;
}
.toolCategory {
    .mdi {
        margin-right: 0.3rem;
    }
}

.is-overlay {
    top: 2.2rem;
    left: 0.75rem;
}


// larger browser width breakpoints
@include desktop {
    .toolsList {
        margin-right: 0;
    }
}
