@import "~@/styles/constants.scss";

@import "./../styles/constants.scss";
.title:not(:last-child) {
    margin-bottom: 0.5rem;
}
.navbar.is-dark {
    background-color: $mitreDarkNavy;
    color: $cardBackground;
    .navbar-link.is-active, .navbar-brand .navbar-link:focus, .navbar-brand .navbar-link:hover, .navbar-brand>a.navbar-item.is-active, .navbar-brand>a.navbar-item:focus, .navbar-brand>a.navbar-item:hover {
        background-color: $mitreDarkNavy;
        color: $mitreLightBlue;
    }
    h2 {
        letter-spacing: 2px;
    }
    .navbar-item {
        color: $cardBackground;
    }
    img {
        height: 40px; // mimic newscenter
        margin: 0 1rem 0 0;
        width: auto;
        filter: invert(1);
        max-height: 4rem;
    }
    .navbar-end {
        justify-content: flex-end;
        display: flex;
    }
}
@media (min-width: $tablet) {
    .navbar.is-dark {
        img {
            height: 60px; // mimic newscenter
        }
    }
}
