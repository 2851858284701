// Import Bulma\'s core variables (so that we can use them in the Vue components)
@import "~bulma/sass/utilities/_all";

/* Application specific variables*/

/* MITRE legacy brand*/
$mitrePrimaryBlue: #005B94;
$mitrePrimaryGrey: #5f636a;
$mitreSecondaryGreen: #BFD228;
$mitreSecondaryBlue: #00B3DC;
$mitreSecondaryYellow: #FFE23C;
$mitreSecondaryOrange: #F7901E;
$mitreSecondaryRed: #C6401D;
$emergencyNewsRed: $mitreSecondaryRed;
$themeGreen: #9bc25b;

/* MITRE new brand*/
$mitreHighlighterYellow: #fff601;
$mitreDarkNavy: #0b2338;        //Johns header #0a1f32
$mitreNavy: #0d2f4f;
$mitreLightBlue: #87deff;
$mitreDarkGrey: #7e8284;
$mitreSilver: #d4d4d3;
$mitreLightSilver: #f1f3f4;
$mitreOrange: $mitreSecondaryOrange;
$mitreGreen: $mitreSecondaryGreen;
$mitreAttackRed: $mitreSecondaryRed;
$mitreEngenuityPurple: #6241c5;

$fontLink: $mitrePrimaryBlue;
$fontLinkVisited: $mitrePrimaryBlue;
$fontBody: #333333;
$fontTitle: #333333;

$contentBackground: $mitreLightSilver;
$cardBackground: #ffffff;
$colorBackground: $contentBackground;

/* Additional app colors*/
$textGrey: #555555;
$finePrintGrey: #767676;
$mediumIconGrey: #adadad;
$backgroundGrey: #e5e5e2;
$blueGrey: #6399bf;
$steelBlue: #3a677a;
$tonedDownOrange: #F39E4F;
$mitreWarningIcon: #E0B514;
$tonedDownLightBlue: #abe8ff; /*lighter (70%) $mitreLightBlue*/
$bolderLightBlue: #60cef8;      /*darker $mitreLightBlue*/
$mitreWarningBackground:  #FFFFD7;
$lighterBlueBackground: #DBF5FF;
$opaqueMitreOrange: #FEE0C1;    /*opaque version of @mitreSecondaryOrange*/

/* Update Bulma's Global Variables*/
$family-sans-serif: "Arial", sans-serif;
$family-sans-serif-option: "Source Sans Pro", sans-serif;      /* define a special header font*/
$family-primary: $family-sans-serif;
$family-secondary: $family-sans-serif-option;
$body-size: 14px;
$gap: $body-size * 2;
$grey-dark: $mitreDarkGrey;
$grey-light: $mitreSilver;
$primary: $fontBody;
$text: $fontBody;
$info: $finePrintGrey;
$link: $mitrePrimaryBlue;
$warning: $mitreWarningIcon;
$danger: $mitreAttackRed;
/*$widescreen-enabled: false;
$fullhd-enabled: false;*/

/*Update some of Bulma's Component Variables*/
$body-background-color: $mitreLightSilver;
// $background: $mitreLightSilver;
$card-background-color: white;
$control-border-width: 2px;
$content-heading-color: $mitreDarkNavy;
$input-border-color: transparent;
$input-shadow: none;
$subtitle-color: $finePrintGrey;

$grey-darker: $mitrePrimaryGrey;
$grey-dark: $finePrintGrey;
$greyLight: $mitreSilver;
$greyLighter: $mitreLightSilver;

$menu-item-active-color: $fontLink;
$menu-item-hover-color: $fontLink;
$menu-item-active-background-color: $mitreLightBlue;
$menu-item-hover-background-color: $mitreLightBlue;

/*$size-1	size	3rem
$size-2	size	2.5rem
$size-3	size	2rem
$size-4	size	1.5rem
$size-5	size	1.25rem
$size-6	size	1rem
$size-7	size	0.75rem*/
