@import "~@/styles/constants.scss";
@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,400&display=swap');

// import Constants
@import 'constants.scss';

// Import only what you need from Bulma
/*@import "./../../node_modules/bulma/sass/utilities/_all.sass";
@import "./../../node_modules/bulma/sass/base/_all.sass";
@import "./../../node_modules/bulma/sass/elements/button.sass";
@import "./../../node_modules/bulma/sass/elements/container.sass";
@import "./../../node_modules/bulma/sass/elements/title.sass";
@import "./../../node_modules/bulma/sass/form/_all.sass";
@import "./../../node_modules/bulma/sass/components/navbar.sass";
@import "./../../node_modules/bulma/sass/layout/hero.sass";
@import "./../../node_modules/bulma/sass/layout/section.sass";*/

// Bulma Import
@import '~bulma';

/*Over-ride Bulma Styling*/
body {
    background-color: $white;
}
/* special h tag (headers) font */
.title {
    font-family: $family-sans-serif-option;
}
h2, h3, h4 {
  font-family: $family-sans-serif-option;
}
h3, h3.subtitle {
  font-weight: 600;
  // color: $mitreDarkGrey;
}
h4 {
  font-weight: 600;
}

button, a {
    cursor: pointer;
}
button:hover, a:hover, a:active {
  color: $fontLink;
}

.linkText {
  color: $mitrePrimaryBlue;
}
.understatedText {
  color: $mitreSilver;
}
.titleSecondaryText {
  color: $mediumIconGrey;
  font-weight: 500;
}
.infoIconGreyText {
  color: $finePrintGrey;
}

/*generic list*/
.content ul {
  padding-left: 1em;
  margin: 0;
  line-height: 1.2em;
  list-style-type: none;
  list-style-position: outside;
  li:not(:last-child) {
    padding-bottom: 0.3em;
  }
}

/*global spacing and colors for page areas */
.secondary-menu {
    margin-top: 1.5em;  // match main-content top spacing
}

.main-content {
    background-color: $white;
    padding-top: 2em;
    padding-left: 2em;
}
// extra-large icons
.is-extra-large {
  width: 3rem;
  height: 3rem;
  svg {
    height: 3rem;
    width: 3rem;
  }
}

// Capitalizes the first letter (can only be used on block elements like <p>)
.capitalizeFirstLetter {
  display: inline-block;
  &::first-letter {
    text-transform: capitalize;
  }
}

hr {
  background-color: $mitreSilver;
  height: 1px;
}
