@import "~@/styles/constants.scss";

@import "../styles/constants.scss";
.primary-menu {
    height: auto;
    .menu-list {
        a {
            padding: 0 0;
            button {
                border: none;
                color: $cardBackground;
                cursor: pointer;
                width: 100%;
                padding: 1em 0;
            }
            &.router-link-exact-active {
                background-color: $mitreLightSilver;
                .icon {
                    color: $mitreDarkNavy;
                }
            }
        }
        a:hover {
            .icon {
                color: $mitreDarkNavy;
            }
        }
    }
}
.menu, button {
    background-color: $mitreSilver;
    color: $contentBackground;
}
li {
    border-bottom: 1px solid $mitreLightSilver;
}

@include tablet {
    .primary-menu {
        height: 100vh;            // full viewport height
    }
}
