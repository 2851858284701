@import "~@/styles/constants.scss";

@import "./styles/constants.scss";
#app-header {
    background-color: $mitreDarkNavy;
    border-bottom: 3px solid $mitreHighlighterYellow;
}
#main-portal-container {
    padding-top: .7em;
    background-color: $white;
    .column.is-narrow-tablet {
        width: 100%;
    }
}
#launch-menu.column {
    padding-right: 0;
    padding-bottom: 0;
    background-color: $mitreSilver;
}
#launch-content.column {
    padding-left: 0;
    padding-top: 0;
}
#app-footer {
    text-align: center;
    padding: 1rem 0rem 1rem 0rem;
    background-color: $mitreLightSilver;
    margin-top: -0.75rem;
}

@include tablet {
    #main-portal-container {
        .column.is-narrow-tablet {
            width: 6em;
        }
    }
}
