@import "~@/styles/constants.scss";

.main-content {
    margin-right: 2rem;
}
.engagementsContainer {
    background-color: $mitreLightSilver;
    padding: 1rem 1rem;
}
// toggle hidden tool area
ul {
    padding-left: 0;
}
ul:last-of-type {
    margin-bottom: 0;
}
li:last-of-type {
    margin-bottom: 0;
}

// larger browser width breakpoints
@include widescreen {
    .main-content {
        margin-right: 0;
    }
}
